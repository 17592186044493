export default {
  process: {
		loading: false,
		error: null,
		succeed: false
	},
	mediaType: null,
	category: null,
	epId: '',
	eventId: '',
	title: {},
	description: {},
	tags: [],
	location: '',
	seoTitle: {},
	seoDescription: {},
	seoKeywords: {},
	eventDate: null,
	eventEndDate: null,
	modifiedDate: null,
	startPublicationDate: null,
	endPublicationDate: null,
	startEmbargoDate: null,
	endEmbargoDate: null,
	termsOfUse: {},
	copyrightAuthor: {},
	copyrightYear: '',
	copyrightLicense: {},
	links:[],
	owner: '',
	creationDate: null,
	updateDate: null,
	associatedMedia: {
		loading: false,
		error: false,
		succeed: false,
		data: []
	},
	associatedTopics: {
		loading: false,
		error: false,
		succeed: false,
		data: null,
		automatic: null
	},
	highlightedMedia: {
		loading: false,
		error: false,
		succeed: false,
		data: []
	},
};