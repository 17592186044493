import { useCallback, useMemo,useRef,useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Card, Row, Select, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
const { Option } = Select;

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import Validate from '@Components/Validate';
import DragUpload from '@Components/BasicComponents/DragUpload';
import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';
import BasicLabel from '@Components/BasicComponents/BasicLabel';

import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';

import { filesUploaded } from '@/utils/errors/list';
import InfoTooltip from './InfoTooltip';

export default function  Attachment({handleChange, attachment, description, remove, id, countryCode, setCountryCode, selector}) {
	const {languages} = useSelector(mediaSettingsSelector);

	const {uploadAtatchment, removeAtatchment, epId} = selector();

	const useIbmS3UploaderRef = useRef(
		useIbmS3Uploader({
			setFile: (value) => uploadAtatchment({...value, id}),
			removeFile: () => removeAtatchment({id, countryCode}),
			bucketPrefix: `${epId?.data}/`
		})
	);
	const {handleUpload, handleRemove,updateBucketPrefix} = useIbmS3UploaderRef.current;

	const fileList = useMemo(() => attachment[countryCode] ? [{uid: 0, ...attachment[countryCode]}] : [ ], [attachment[countryCode]]);
	
	const onChange = useCallback(
		(param) => {
			return ({countryCode, value}) => handleChange({param, countryCode, value});
		}, []);

	useEffect(() => {
		updateBucketPrefix(`${epId?.data}/`);
	}, [epId?.data, countryCode]);

	return (
		<Card 
			style={{ marginTop: 16 }}
		>
			<Row justify="space-between" align="middle">
				<BasicLabel
					title="Attachment"
					tooltip="An additional file uploaded alongside the media content, such as documentation or supporting materials."
				/>
				<Space>
					<InfoTooltip
						text="To indicate and define the language in which the media content is presented. English is mandatory for the required fields."
						style={{marginRight: 20}}
					/>
					<Select
						value={countryCode}
						style={{ width: 150 }}
						onChange={setCountryCode}
						loading={languages.loading}
					>
						{
							languages.data?.map(({id, nameIso}) =>
								<Option key={id} value={id}>
									<Space>
										{nameIso}
										{attachment[id]  && <CheckOutlined />}
									</Space>
								</Option>)
						} 
					</Select>
				</Space>
			</Row>
			<br/>
			<Validate requirements={[filesUploaded]}>
				<DragUpload
					listType="picture"
					value={fileList}
					title="Attatchment"
					maxCount={1}
					handleUpload={/* handleUpload */ (file) => handleUpload({...file, countryCode})}
					onRemove={handleRemove}
					accept=".docx, .pdf, .zip, .csv, .xml, .pptx, .xlsx"
					multiple={false}
					bucketPrefix={countryCode}
				/>
			</Validate>
			<br/>
			<MultiLanguageInput
				title="Descriptive text"
				value={description}
				onChange={onChange('description')}
				style={{marginVertical: 10}}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
				tooltip="An alternative description of the link or attachment that summarises the referral information."
			/>
			<br/>
			<Button type="link" size="small" danger onClick={remove}>Remove</Button>
		</Card>
	);
}