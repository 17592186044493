import { createContext } from "react";
import {  useSelector } from "react-redux";

import authSelector from '@Redux/auth/authSelector';

import useContextState from "@/hooks/useContextState";

import { postData, getData } from '@/utils/api';
import { transformDataToUpload, transformFetchedData } from '@/utils/transformData';
import { transformMediaType } from '@/utils/basics';

import initialData from "./initialData";

export const MediaContext = createContext({
	countryCode: 'en_GB'
});

const initialStateData = JSON.parse(JSON.stringify(initialData));

export function MediaContextProvider({ children }) {
  const dataAndFunctions = useContextState({initialData, initialStateData});
  const { data, setData, setProcess } = dataAndFunctions;
  const {jwttoken: auth} = useSelector(authSelector);

  const setEpId = (epId) => {
    setData((currentData) => {
      return {
        ...currentData,
        epId: {
          ...currentData.epId,
          ...epId
        }
      };
    });
  };

  const setEpIdValue = (value) => {
    setEpId({
      data: value
    });
  };

  const getEpId = async ({data, type}) => {
    try {
      setEpId({
				loading: true,
				error: null,
			});

      const response = await postData({auth, data, endpoint: `/${type}/epId`});

      if (response.error) {
        throw(new Error(response.message));
      }

      setEpId({
				loading: false,
				error: null,
				data: response.content.optionsLists[0].id,
			});

    } catch (err) {
      setEpId({
				loading: false,
				error: err.message || 'Something went wrong',
			});
    }
  };

  const upload = async () => {
    setProcess({
      loading: true,
      error: null,
    });

		const transformedData = transformDataToUpload(data);
		const fetchData = async () => {
			try {
				const response = await postData({auth, data: transformedData, endpoint: `/${transformMediaType(data.mediaType)}`});

				if (response.error) {
					throw(new Error(response.message));
				}

				setProcess({
          loading: false,
					error: null,
					succeed: true
        });

			} catch (err) {
        console.log(err);
				setProcess({
          loading: false,
					error: err.message || 'Something went wrong',
        });
			}
		};

		return await fetchData();
	};

  const getItemData = async ({epId, type}) => {
    setProcess({
      loading: true,
      error: null,
    });

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: `/${type}/${epId}`});

				if (response.error) {
					throw(new Error(response.message));
				}

				setData((currentData) =>{
          return {
            ...currentData,
            ...transformFetchedData(response.content, type === 'streamings'),
            process: {
              edit: true,
              loading: false,
              error: null,
              succeed: false
            }
          };
        });

        setProcess({
          loading: false,
					error: null,
        });

			} catch (err) {
				setProcess({
          loading: false,
					error: err.message || 'Something went wrong',
        });
			}
		};

		return await fetchData();
	};

  return (
    <MediaContext.Provider
      value={{
        ...dataAndFunctions,
        setEpIdValue,
        getEpId,
        upload,
        getItemData
      }}
    >
    {children}
    </MediaContext.Provider>
  );
}