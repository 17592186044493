import { getSetting } from "../utils/settings";

const urlPrefix = getSetting('URL_PREFIX');

export const HOME = urlPrefix +'/';
export const LOGIN = urlPrefix + '/login';

export const FORGOT_PASSWORD = urlPrefix + '/forgot-password';
export const EVENT = urlPrefix + '/events';
export const LIST_EVENT = EVENT + '/list';
export const TAGS = urlPrefix + '/tags';

export const MEDIA = urlPrefix + '/medias';
export const LIST_MEDIA = MEDIA + '/list';
export const CREATE_MEDIA = MEDIA +'/create';
export const EDIT_MEDIA = MEDIA +'/edit';
export const RELEVANT_MEDIA = MEDIA + '/relevant';

export const TOPICS = urlPrefix + '/topics';
export const LIST_TOPIC = TOPICS + '/list';
export const EDIT_TOPIC = TOPICS + '/edit';
export const CREATE_TOPIC = TOPICS + '/create';
export const SPOTLIGHTED_TOPICS = TOPICS + '/spotlighted';
export const FEATURED_TOPICS = TOPICS + '/featured';

export const HOMEPAGE = urlPrefix + '/homepage';
export const HOMEPAGE_MEDIA_CARDS = HOMEPAGE + '/media-cards';
export const HOMEPAGE_LIVE_STREAMING = HOMEPAGE + '/live-streaming';
export const HOMEPAGE_SERVICES_INFORMATION_CARDS = HOMEPAGE + '/services-information-cards';
export const HOMEPAGE_SERVICE_MESSAGE = HOMEPAGE + '/service-message';
export const HOMEPAGE_PROMOTIONAL_BANNER = HOMEPAGE + '/promotional-banner';

export const DASHBOARDS = urlPrefix + '/monitoring-dashboards';
export const SYSTEM_DASHBOARDS = DASHBOARDS + '/system-dashboards';
export const DASHBOARD_FILE_PROCESSING = DASHBOARDS + '/file-processing';
export const DASHBOARD_MOST_VISITED = DASHBOARDS + '/most-visited';
export const DASHBOARD_MOST_DOWNLOADED = DASHBOARDS + '/most-downloaded';

export const USERS = urlPrefix + '/users';
export const CREATE_USER = USERS + '/create';
export const LIST_USER = USERS + '/list';

export const TASKS = urlPrefix + '/tasks';
export const LIST_TASK = TASKS + '/list';
export const CREATE_TASK = TASKS + '/create';

export const MULTILINGUAL_DICTIONARIES = urlPrefix + '/multilingual-dictionaries';
export const SYNONYMS = MULTILINGUAL_DICTIONARIES + '/synonyms';
export const HYPONYMS = MULTILINGUAL_DICTIONARIES + '/hyponyms';
export const ACRONYMS = MULTILINGUAL_DICTIONARIES + '/acronyms';
export const KEYWORDS_EXPANSION = MULTILINGUAL_DICTIONARIES + '/keywords-expansion';
export const ALIASES = MULTILINGUAL_DICTIONARIES + '/aliases';
export const AUTOCORRECTION_WORDS = MULTILINGUAL_DICTIONARIES + '/autocorrection-words';