import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Button, Checkbox, Col, Row, Space, Spin } from "antd";

import useNotification from '@/hooks/notifications';

import BasicDatePicker from '@Components/BasicComponents/BasicDatePicker';
import TagList from '@Components/BasicComponents/TagList';

import { dateToISOString } from "@/utils/dates";

import { TopicContext } from "@/context/topicContext/topicContext";
import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

const buttonStyle = {
  width: 200,
  padding: '5px 10px',
  borderRadius: '100px'
};

export default function AssociationCriteria () {
  const navigate = useNavigate();
  const {
    getTopicAssociationCriteria,
    setTopicAssociationCriteria,
    data: { process }
  } = useContext(TopicContext);
  const { mediaTypes } = useSelector(mediaSettingsSelector);

  const {openNotification} = useNotification();

  const initialTags = useRef([]);

  const [data, setData] = useState({
    tags: [],
    startDate: '',
    endDate: '',
    mediaTypes: []
  });

  useEffect(() => {
    getData();
  }, []);

  const options = useMemo(() => mediaTypes.data.map(mediaType => {
    return {
      label: mediaType.id !== '601'
        ? mediaType.label + 's'
        : mediaType.label,
      value: parseInt(mediaType.id),
    };
  }), [mediaTypes.data]);

  const getData = async () => {
    const {error, content, ...res} = await getTopicAssociationCriteria();

    if (!error) {
      if (content?.tags?.length) {
        content.tags = content.tags.map(id => id.toString());
        initialTags.current = content.tags;
      }

      setData((current) => {
        return {
          ...current,
          ...content,
        };
      });
    } else {
      openNotification({message: res.title, type: 'error'});
    }
  };

  const putData = async () => {
    const {error, content, ...res} = await setTopicAssociationCriteria(data);

    if (!error) {
      if (content?.tags?.length) {
        content.tags = content.tags.map(id => id.toString());
      }
      setData((current) => {
        return {
          ...current,
          ...content
        };
      });
      openNotification({message: 'Successful upload', description: 'The association criteria has been uploaded successfully.', type: 'success'});
    } else {
      openNotification({message: res.title, type: 'error'});
    }
  };

  const handleChange = (param, isDate = false) => {
    return (value) =>
      setData((currentData) => {
        if (isDate) {
          value = value ? dateToISOString(value) : null;
        }
        return {
          ...currentData,
          [param]: value
        };
      });
  };

  return (
		<Spin spinning={process.loading}>
      <Space size="large" direction='vertical' style={{width: '100%'}} >
        <h3>Tag AssociationCriteria</h3>
        <TagList
          title="Tags"
          value={data.tags}
          onChange={handleChange('tags')}
          initialData={initialTags.current}
        />
        <br />
        <h3>Publishing Dates</h3>
        <Row gutter={[16, 24]} justify="start">
          <Col className="gutter-row" md={24} lg={12} xl={8}>
            <BasicDatePicker
              title="Start date"
              value={data.startDate}
              onChange={handleChange('startDate', true)}
            />
          </Col>
          <Col className="gutter-row" md={24} lg={12} xl={8}>
            <BasicDatePicker
              title="End date"
              value={data.endDate}
              onChange={handleChange('endDate', true)}
            />
          </Col>
        </Row>
        <br />
        <h3>Media type association</h3>
        <Checkbox.Group
          options={options}
          value={data.mediaTypes}
          onChange={handleChange('mediaTypes')}
        />
        <br />
        <Row justify="center" gutter={[64, 16]}>
          <Col>
            <Button onClick={() => navigate(-1)} danger type="primary" size='large' style={buttonStyle}>Cancel</Button>
          </Col>
          <Col>
            <Button onClick={() => putData()} type="primary" size='large' style={buttonStyle}>Confirm Upload</Button>
          </Col>
        </Row>
      </Space>
    </Spin>
  );
}