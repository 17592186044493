import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from '@Redux/auth/authSlice';
import mediaSettingsSlice from '@Redux/mediaSettings/mediaSettingsSlice';
import uploadErrorsSlice from '@Redux/uploadErrors/uploadErrorsSlice';
import electionsSlice from '@Redux/elections/electionsSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	mediaSettings: mediaSettingsSlice,
	uploadErrors: uploadErrorsSlice,
	elections: electionsSlice,
});


export const store = configureStore({
	reducer: rootReducer,
});
