import { findErrors } from '.';
import { isObject } from '../basics';

function errConstructor ({isOk = false, isWarning = false, text = '', element = ''}) {
	if (isWarning) {
		return {text, isOk: true, show: true, type: 'warning', element};
	}

	if (isOk) {
		return {text: '', isOk: true, show: false, element };
	}

	return {text, isOk, type: 'error', show: true, element};
}

export function required (value, element) {
	if (!value) {
		return errConstructor({text: 'This value is required', element});
	}
	if (isObject(value) && Object.entries(value).length === 0) {
		return errConstructor({text: 'This value is required', element});
	}
	if (isObject(value)) {
		let error = true;
		Object.values(value).forEach(value => {
			if (value && value.trim() !== '') {
				error = false;
			}
		});

		if (!error) {
			return errConstructor({isOk: true, element});
		}
		return errConstructor({text: 'This value is required', element});
	}
	return errConstructor({isOk: true, element});
}

export function fileRequired (value, element) {
	if (isObject(value)) {
		let error = true;
	
		Object.values(value).forEach((value) => {
			if (value.length !== 0) {
				error = false;
			}

		});
		return error ? errConstructor({text: 'This value is required', element}) : errConstructor({isOk: true, element});
	}

	if (value.length === 0) {
		return errConstructor({text: 'This value is required', element});
	}

	return errConstructor({isOk: true, element});
}

export function filesUploaded (value, element) {
	let allDone = true;

	if (isObject(value)) {
		Object.values(value).forEach((value) => {
			value.forEach(file => {
				if (file.status !== 'done') {
					allDone = false;
				}
			});
		});
	} else {
		value.forEach(file => {
			if (file.status !== 'done') {
				allDone = false;
			}
		});
	}

	if (!allDone) {
		return errConstructor({text: 'All files must be uploaded before proceeding.', element});
	}

	return errConstructor({isOk: true, element});
}

export function languagesRequired (languages) {
	return (value, element) => {
		const isEmpty = !required(value).isOk;

		if (isEmpty) {
      return errConstructor({isOk: true, element});
    }

		for( let i = 0; i < languages.length; i++) {
			const langCode = languages[i];
			if (!value[langCode] || value[langCode] === '') {
				return errConstructor({text: `This value is required in ${langCode}`, element});
			}
		}

		return errConstructor({isOk: true, element});
	};
}

export function languageRequired (language) {
	return (value, element) => {
		const isEmpty = !required(value).isOk;

		if (isEmpty) {
      return errConstructor({isOk: true, element});
    }

		if (Object.keys(value).length > 0 && (!value[language] || value[language] === '')) {
			return errConstructor({text: `This value is required in ${language}`, element});
		}

		return errConstructor({isOk: true, element});
	};
}

export function numberRequired (value, element) {
	const enValues = value['en_GB'] || [];

	let error = errConstructor({text: 'The infografic number 001 is required in english.', element});

	enValues.forEach(value => {
		const num = value.name.split('_').pop().slice(0,3) || '';

		if (num === '001') {
            error = errConstructor({isOk: true, element});
        }
	});

	return error;
}

export function maxChars(num, options) {
	const {isWarning = false} = options;

	return (value, element) => {
		if (isObject(value)) {
			let err = errConstructor({isOk: true, element});

			const errorLanguages = [];

			Object.entries(value).forEach(([lang, string]) => {
				if (string.length > num) {
					errorLanguages.push(lang);
				}
			});

			if (errorLanguages.length > 0) {
				const langs = errorLanguages.join(', ');
				err = errConstructor({text: `Max. ${num} characters (${langs})`, isWarning, element});
			}

			return err;
		}

		if (value.length > num) {
			return errConstructor({text: `Max. ${num} characters`, element});

		}

		return errConstructor({isOk: true, element});
	};
}

export function maxKeywords(num) {
	return (value, element) => {
		if (isObject(value)) {
			let err = errConstructor({isOk: true, element});

			const errorLanguages = [];
		
			Object.entries(value).forEach(([lang, string]) => {
				const keywords = string.split(',');
				if (keywords.length > num) {
					errorLanguages.push(lang);
				}
			});

			if (errorLanguages.length > 0) {
				const langs = errorLanguages.join(', ');
				err = errConstructor({text: `Max. ${num} keywords (${langs})`, element});
			}

			return err;
		}

		return errConstructor({isOk: true, element});
	};
}

export function isYear (value, element) {
	if (value === '') {
		return errConstructor({isOk: true, element});
	}
	const regex = new RegExp('^(19|20)\\d{2}$');
	if (!regex.test(value)) {
		return errConstructor({text: 'This is not a valid year', element});
	}

	return errConstructor({isOk: true, element});
}

export function isLink (value, element) {
	if (isObject(value)) {
		let err = errConstructor({isOk: true, element});
	
		Object.values(value).forEach((string) => {
			if (string !== '' && !string.startsWith('http') && !string.startsWith('https')) {
				err = errConstructor({text: 'The link must start with http or https', element});
			}

		});
		return err;
	}
	if (value !== '' && (value.startsWith('https') || value.startsWith('https'))) {
		return errConstructor({text: 'The link must start with http or https', element});

	}

	return errConstructor({isOk: true, element});
}

export function infographicErrors (values, element) {
	const  entries = Object.entries(values);

	const requirements = {
		'png': [filesUploaded, numberRequired],
		'jpg': [filesUploaded],
		'vector': [filesUploaded],
	};

	for (let i = 0; i < entries.length; i++) {
		const [key, value] = entries[i];
		let error = findErrors(value, requirements[key]);

		if (!error.isOk) {
			error.text += ` (${key})`;
            return error;
        }
	}

	if (!values?.png) {
		return errConstructor({text: 'A PNG file is required', element});
	}

	return errConstructor({isOk: true, element});
}