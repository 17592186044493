import { getSetting } from '@/utils/settings';
import { prepareTagList } from '@/utils/basics';

export const getTag = async (tag) => {
		const server = getSetting('REACT_APP_EPMP_BASE_URL') + `/tags/${tag}`;

    try {
      const response = await fetch(server, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.status!== 200) {
        throw new Error(response);
      }

      return response.json();

    } catch (err) {
      return tag;
    }
  };

  export const  getTags = async (tags, full = false) => {
    let newTags = await Promise.all(
      tags.map(getTag)
    );

    newTags = newTags.map(({content}) => content);

    if (full) {
      return prepareTagList(newTags);
    }
    return newTags.map(tag => tag?.title || tag);
  };