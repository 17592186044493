import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Result, Space, Spin, Modal } from 'antd';

import AudioForm from '@Components/Forms/AudioForm';
import VideoForm from '@Components/Forms/VideoForm';
import PodcastForm from '@Components/Forms/PodcastForm';
import InfographicForm from '@Components/Forms/InfographicForm';
import StreamingForm from '@Components/Forms/StreamingForm';
import MediaDefinition from '@Components/ComposedComponents/MediaDefinition';

import useCustomPrompt from '@/hooks/useCustomPrompt';
import useNotification from '@/hooks/notifications';

import { ErrorsContext } from '@/context/errorsContext';
import { MediaContext } from '@/context/mediaContext/mediaContext';

export default function MediaForm ({edit = false, viewOnly = false}) {
	const selector = () => useContext(MediaContext);

	const {mediaType, category, process: {loading, error, succeed}, setInitialState, getItemData} = selector();
	const { resetErrors } = useContext(ErrorsContext);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [okCb, setOkCb] = useState(() => {});

	const {openNotification} = useNotification();

	const { type, epId } = useParams();
	const navigate = useNavigate();

	const props = useMemo(() => ({
		selector,
		disabled: viewOnly,
		className: (viewOnly || edit) ? "neutralForm" : ""
	}), [viewOnly]);

	useEffect(() => {
		if(type && epId) {
			getItemData({type, epId});
		}
	}, [type, epId]);

	useEffect(resetErrors, [mediaType]);

	useEffect(()=> {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error || null, type: 'error'});
		}
	}, [error]);

	const openModal = (onOk) => {
			setOkCb(() => onOk);

			setIsModalOpen(true);
	};

  const handleOk = () => {
		okCb();
		setInitialState();
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	function goBack () {
		setInitialState();
		resetErrors();
		navigate(-1);
	}

	useCustomPrompt(openModal, mediaType && mediaType !== "WS_VIDEO");

	if (succeed) {
		return (
			<Result
				status="success"
				title={
					edit
					? "Successful edit!"
					: "Successful upload"
				}
				subTitle={
					edit
					? "The file has been editted successfully."
					: "The file has been uploaded successfully."
				}
				extra={[
					<Button type="primary" key="back" onClick={goBack}>
						Go Back
					</Button>
				]}
			/>
		);
	}

	return (
		<Spin spinning={loading}>
			<Modal title="Warning!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>You have entered data that will be lost if you exit the page</p>
			</Modal>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
				<h1>{`Media > ${edit ? 'Edit media' : viewOnly ? 'Details' : 'Upload a new media'}`}</h1>
				<MediaDefinition
					exclude={edit || viewOnly ? [] : ['EPV_AUDIO_PODCASTS','WS_VIDEO', 'EVENT']}
					edit={edit}
					{...props}
				/>
				{ mediaType ==='EPV_AUDIO' && <AudioForm {...props}/> }
				{ mediaType ==='EPV_AUDIO_PODCASTS' && <PodcastForm {...props}/> }
				{ mediaType ==='video' && <VideoForm {...props}/> }
				{ mediaType ==='EPV_INFOGRAPHICS' && <InfographicForm interactive={category === 'EPV_INFOGRAPHICS_INTERACTIVE'} {...props}/> }
				{ mediaType ==='WS_VIDEO' && <StreamingForm {...props}/> }
			</Space>
		</Spin>
	);
}