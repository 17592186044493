import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {  Pagination, Radio, Row, Select, Space, Tabs } from "antd";
import Search from "antd/es/input/Search";

import { TopicContext } from '@/context/topicContext/topicContext';

import AssociatedTable from '@Components/Tables/AssociatedTable';

import useFetchListData from '@/hooks/useFetchListData';
import useNotification from '@/hooks/notifications';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';


const options =  [
    {label: 'UNSELECTED MEDIA', value: 'false'},
    {label: 'SELECTED MEDIA', value: 'true'}
];

const searchTypes = [
	{ label: 'Search by reference ID', value: 'epId' },
	{ label: 'Search by keywords', value: 'search' },
];

export default function AssociatedMedia () {
    const {openNotification} = useNotification();

	const { topicId } = useContext(TopicContext);
    const { mediaTypes } = useSelector(mediaSettingsSelector);

    const types = useMemo(() => {
        const order = [ '24', '23', '26', '25', '601', '105' ];
        const t = [];
        mediaTypes.data.forEach(({id, value, label}) => {
            if (id == '23') {
                value = 'EPV_EDITED_VIDEOS';
            }
            if (id !== '701' && id!== '34') {
                const i = order.indexOf(id);
                t[i] = { value, label };
            }
        });

        return t;
    }, [mediaTypes.data]);

    const params = useMemo(() => {
        return {
            mediaType: 'EPV_AUDIO',
            sort: 'modified:desc',
            url: `topics/${topicId}/medias/associated`,
            manualAssociated: true,
            associatedMedia: true,
        };
    }, []);
	const {setParam, setParams, searchParams, data, loading, pagination, error, deleteParam, getData, toggleSelectNew} = useFetchListData({params});

    const type = useMemo(() => searchParams.get("mediaType") ?? 'EPV_AUDIO', [searchParams.get("mediaType")]);
    const selected = useMemo(() => searchParams.get("associatedMedia") ?? 'true', [searchParams.get("associatedMedia")]);
    const automatic = useMemo(() => searchParams.get("manualAssociated")
        ? searchParams.get("manualAssociated") === 'false'
        : false
    , [searchParams.get("manualAssociated")]);

    useEffect(() => {
        if (error) {
            openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
        }
    }, [error]);

    const handleType = ({ target: { value } }) => {
		deleteParam('page', false);
		setParams({mediaType: value});
	};

    const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

    const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

    const handleSelected = (value) => {
        deleteParam('page', false);
        setParams({associatedMedia: value});
    };

    const handleManual = (value) => {
        deleteParam('page', false);

        if (value === 'automatic') {
            deleteParam('associatedMedia', false);
            setParams({manualAssociated: false});
            return;
        }

        setParams({manualAssociated: true});
    };

    const items = [
        {
            key: 'manual',
            label: <span>MANUALLY ASSOCIATED MEDIA <Select
                    options={options}
                    value={selected}
                    onChange={handleSelected}
                    style={{width: 200, marginLeft: 10}}
                /></span>,
        },
        {
            key: 'automatic',
            label: 'AUTOMATIC ASSOCIATED MEDIA',
        },
    ];

    return(
        <Space size="large" direction='vertical' style={{width: '100%'}} >
            <Tabs
                defaultActiveKey="1"
                items={items}
                size="large"
                onChange={handleManual}
                activeKey={automatic ? 'automatic' : 'manual'}
            />
            <Radio.Group
                options={types}
                onChange={handleType}
                value={type}
                optionType="button"
                size="large"
            />
            <Search
                addonBefore={
                    <Select
                        style={{width: 190}}
                        options={searchTypes}
                        value={searchParams.get("searchType") || 'search'}
                        onChange={setParam('searchType')}
                    />
                }
                value={searchParams.get("search") || ''}
                onChange={setParam('search', false)}
                onSearch={handleSearch}
                enterButton
            />
            <>
                <AssociatedTable
                    data={data}
                    loading={loading}
                    sort={searchParams.get("sort") || 'mediaDate:desc'}
                    setSort={setParam('sort')}
                    getListData={getData}
                    type={mediaTypes.data.find(type => type.value === type)?.label}
                    name="medias"
                    selectItem={(id) => toggleSelectNew({select: true, url: `/topics/${topicId}/medias/${id}/associated`})}
                    unselectItem={(id) => toggleSelectNew({select: false, url: `/topics/${topicId}/medias/${id}/associated`})}
                    dispatched={false}
                    blocked={automatic}
                />
                {mediaTypes.data.find(type => type.value === type)?.label}
                <Row justify="center">
                    <Pagination
                        total={pagination.totalElements}
                        pageSize={pagination.pageSize}
                        current={pagination.page}
                        showSizeChanger
                        showQuickJumper
                        hideOnSinglePage
                        disabled={loading}
                        onChange={handlePagination}
                    />
                </Row>
            </>
        </Space>
    );
}
