import { Col, Row } from 'antd';

import BasicDatePicker from '@Components/BasicComponents/BasicDatePicker';
import Validate from '@Components/Validate';

import { required } from '@/utils/errors/list';
import { localTimeToUtc } from '@/utils/dates';



export default function PublishingDates ({simple, selector, ...otherProps}) {
	const {eventDate, startPublicationDate, endPublicationDate, creationDate, modifiedDate, setValue } = selector();

	const handleChange = (param) => {
			return (value) => setValue({param, value: value ? localTimeToUtc(value).format() : null});
	};

	return (
		<>
			<h3>Publishing Dates</h3>
			<Row gutter={[16, 24]} justify="start">
				{simple || <Col className="gutter-row" md={24} lg={12} xl={8}>
					<Validate requirements={[required]}>
						<BasicDatePicker
							title="Event date"
							value={eventDate}
							onChange={handleChange('eventDate')}
							required
							tooltip="The date on which the event associated with the media content occurred. Useful for organising or archiving event-related media."
							{...otherProps}
						/>
					</Validate>
				</Col>}
				<Col className="gutter-row" md={24} lg={12} xl={8}>
					<Validate requirements={[required]}>
						<BasicDatePicker
							title="Start date"
							value={startPublicationDate}
							onChange={handleChange('startPublicationDate')}
							maxDate={endPublicationDate}
							required
							tooltip="The beginning date of the media's publication or when the media content can start to be published and consulted by other users on the Multimedia Centre's public website."
							{...otherProps}
						/>
					</Validate>
				</Col>
				<Col className="gutter-row" md={24} lg={12} xl={8}>
						<BasicDatePicker
							title="End date"
							value={endPublicationDate}
							onChange={handleChange('endPublicationDate')}
							minDate={startPublicationDate}
							tooltip="The ending date of the media's publication or when the media content can finish to be published and consulted by other users on the Multimedia Centre's public website."
							{...otherProps}
						/>
				</Col>
				{creationDate &&
					<Col className="gutter-row" md={24} lg={12} xl={8}>
							<BasicDatePicker
								title="Creation date"
								value={creationDate}
								tooltip="This is the date when the media content is first registered in the Multimedia Centre. This date is set by the Multimedia Centre system, it cannot be selected."
								disabled
								className="neutralForm"
							/>
					</Col>
				}
				{modifiedDate &&
					<Col className="gutter-row" md={24} lg={12} xl={8}>
							<BasicDatePicker
								title="Updated date"
								value={modifiedDate}
								tooltip="This is the last date when the media content has been modified or updated in the Multimedia Centre. This date is set by the Multimedia Centre system, it cannot be selected."
								disabled
								className="neutralForm"
							/>
					</Col>
				}
			</Row>
		</>
	);
}