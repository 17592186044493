import { Link } from 'react-router-dom';
import { Card, Col, Row, Space, Typography } from 'antd';

const { Title } = Typography;
const { Meta } = Card;

import MemoryStatusImage from '@/images/dashboards/memory.webp';
import PerformanceImage from '@/images/dashboards/performance.webp';
import ServerCapacity from '@/images/dashboards/server-capacity.webp';

const cards = [
  {
    id: 1,
    title: 'Memory status',
    internal: false,
    link: 'https://p.datadoghq.eu/sb/enswfis4l4eyclvy-f5866c017a52a2a054b8a1631e179c4d',
    image: MemoryStatusImage
  },
  {
    id: 2,
    title: 'Performance',
    internal: false,
    link: 'https://p.datadoghq.eu/sb/enswfis4l4eyclvy-b8101663694b0af0f7348e29e5a49dd7',
    image: PerformanceImage
  },
  {
    id: 3,
    title: 'Server capacity',
    internal: false,
    link: 'https://p.datadoghq.eu/sb/enswfis4l4eyclvy-e36cf62cf0d4139e19913b9a1808db9f',
    image: ServerCapacity
  },
];

const externalLinkProps = {
  target:"_blank",
  rel:"noopener noreferrer"
};

export default function MonitoringDashboards () {
  return (
		<Space size="large" direction='vertical'>
			<Title>Platform monitoring dashboards</Title>
      <Row gutter={[25, 25]}>
        {cards.map(({id, title, internal, image, link}) =>
          <Col key={id}>
            <Link to={link} {...(!internal && externalLinkProps)}>
              <Card
                hoverable
                style={{
                  width: 200,
                }}
                cover={<img alt="example" src={image} />}
              >
                <Meta
                  title={title}
                  description={internal ? 'Internal link' : 'External link'}
                />
              </Card>
            </Link>
          </Col>
        )}
      </Row>
		</Space>
	);
}