export function getKeyFromUrl(url) {
  const path = url.split('/').slice(1);

  if (path.length === 1 && path[0] === "") {
    return 'home';
  }

  if (path.length === 1) {
    return path[0];
  }

  return path.join('-');
}