import { useRef, useEffect } from 'react';

import BulkUpload from '@Components/BasicComponents/BulkUpload';
import Validate from '@Components/Validate';

import { nameIsEpid } from '@/utils/names';
import { fileRequired, filesUploaded } from '@/utils/errors/list';

import useNotification from '@/hooks/notifications';
import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';

const tooltip = `To upload multiple media files simultaneously for large batches of content.`;

export default function ZipUpload ({selector}) {
	const {files, epId, setFile, removeFileByUid} = selector();
	const {openNotification} = useNotification();

	const useIbmS3UploaderRef = useRef(useIbmS3Uploader({setFile, removeFile: removeFileByUid, bucketPrefix: epId.data + '/'}));
	const {handleUpload, handleRemove, updateBucketPrefix} = useIbmS3UploaderRef.current;

	useEffect(() => {
		updateBucketPrefix(epId.data + '/');
	}, [epId.data]);

	return <>
		<Validate requirements={[fileRequired, filesUploaded]}>
			<BulkUpload
				title="Zip Upload"
				value={files}
				handleUpload={handleUpload}
				accept=".zip"
				beforeUpload={nameIsEpid({id: epId.data, openNotification})}
				handleRemove={handleRemove}
				tooltip={tooltip}
			/>
		</Validate>
	</>;
}