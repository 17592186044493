import { useContext, useState } from "react";
import { Select, Spin, Tabs } from "antd";
import HighlightedMediasTable from "@Components/Tables/HighlightedMediasTable";
import OrderHighlightedMedias from "@Components/Tables/OrderHighlightedMedias";

import { EventContext } from "@/context/eventContext/eventContext";

const options = [
    {label: 'UNSELECTED MEDIA', value: false},
    {label: 'SELECTED MEDIA', value: true}
];

export default function HighlightedMedia () {
    const [selected, setSelected] = useState(true);

    const { eventId } = useContext(EventContext);

    const items = [
        {
            key: '1',
            label: <span>HIGHLIGHTED MEDIA <Select
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    style={{width: 200, marginLeft: 10}}
                /></span>,
            children: <HighlightedMediasTable
                selected={selected}
                url={`event/${eventId}/medias`}
            />,
        },
        {
            key: '2',
            label: 'ORDER HIGHLIGHTED MEDIA',
            children: <OrderHighlightedMedias url={`event/${eventId}/medias`} />,
            destroyInactiveTabPane: true,
        },
    ];

    return<Spin spinning={false}>
            <Tabs defaultActiveKey="1" items={items} size="large" destroyInactiveTabPane />
    </Spin>;
}