import { cloneElement, memo, useEffect, useState } from 'react';
import useValidate from '@/hooks/useValidate';

function Validate ({children, requirements, error = null}) {
	const [lastValue, setLastValue] = useState(null);
	const { err, getErrors} = useValidate({value: children.props.value, element: children.props.title,  requirements });

	useEffect(() => {
		if (lastValue === null) {
			setLastValue(children.props.value
				? JSON.stringify(children.props.value)
				: null
			);
			return;
		}

		const sameValue = lastValue === JSON.stringify(children.props.value);

		if (!sameValue) {
      setLastValue(children.props.value
				? JSON.stringify(children.props.value)
				: null
			);
      getErrors({value: children.props.value, element: children.props.title,  requirements });
    }
	}, [children]);

	return cloneElement(children, {error: error || err});
}

export default memo(Validate);