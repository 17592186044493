import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, Menu } from 'antd';
const { Sider } = Layout;

import items from './items';
import { getKeyFromUrl } from '@/utils/router';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';
import { setMenuOpenTabs } from '@Redux/mediaSettings/mediaSettingsSlice';



export default function SideMenu() {
	const {menuOpenTabs} = useSelector(mediaSettingsSelector);
	const dispatch = useDispatch();
	const location = useLocation();

	const selectedKeys = useMemo(() => getKeyFromUrl(location.pathname), [location.pathname]);
	const handleChange = (keys) => dispatch(setMenuOpenTabs(keys));

	return (
		<Sider
			width={300}
			className='side-menu'
		>
			<Menu
				mode="inline"
				defaultOpenKeys={['content']}
				openKeys={menuOpenTabs}
				selectedKeys={selectedKeys}
				style={{
					minHeight: '100%',
					borderRight: 0,
					paddingTop: 20,
					paddingBottom: 20
				}}
				items={items}
				onOpenChange={handleChange}
			/>
		</Sider>
	);
}