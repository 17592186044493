import { createContext } from "react";
import {  useSelector } from "react-redux";

import authSelector from '@Redux/auth/authSelector';

import useContextState from "@/hooks/useContextState";

import { getData } from '@/utils/api';
import { transformFetchedData } from '@/utils/transformData';

import initialData from "./initialData";

export const EventContext = createContext({
	countryCode: 'en_GB'
});

const initialStateData = JSON.parse(JSON.stringify(initialData));

export function EventContextProvider({ children }) {
  const dataAndFunctions = useContextState({initialData, initialStateData});
  const { setData, setProcess } = dataAndFunctions;
  const {jwttoken: auth} = useSelector(authSelector);

  function setTags (tags) {
    setData((currentData) =>{
      return {
        ...currentData,
        tags
      };
    });
  }

  const getItemData = async ({epId}) => {
    setProcess({
      loading: true,
      error: null,
      succeed: false
    });

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: `/event/${epId}`});

				if (response.error) {
					throw(new Error(response.message));
				}

				setData((currentData) =>{
          return {
            ...currentData,
            ...transformFetchedData(response.content),
            process: {
              edit: true,
              loading: false,
              error: null,
              succeed: true
            }
          };
        });

        setProcess({
          loading: false,
					error: null,
        });

			} catch (err) {
				setProcess({
          loading: false,
					error: err.message || 'Something went wrong',
        });
			}
		};

		return await fetchData();
	};

  return (
    <EventContext.Provider
      value={{
        ...dataAndFunctions,
        getItemData,
        setTags
      }}
    >
    {children}
    </EventContext.Provider>
  );
}