import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Select, Space, Tag, Typography } from 'antd';
import Compact from 'antd/es/space/Compact';
const {Text} = Typography;

import { fetchTagList } from '@Redux/mediaSettings/mediaSettingsFetchers';
import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';
import { setTaglist } from '@Redux/mediaSettings/mediaSettingsSlice';

import { getTags } from '@/utils/fetchFunctions/tags';

import BasicLabel from '@Components/BasicComponents/BasicLabel';

const neutralStyle = {
    color: 'black',
    backgroundColor: 'white'
};

export default function TagList ({
	title,
	value,
	onChange,
	initialData = [],
	error = {isOk: true},
	loading = false,
	disabled = false,
	tooltip = null,
	required = false
}) {
	const dispatch = useDispatch();
	const {tagList} = useSelector(mediaSettingsSelector);

	const handleSearch = (value) => {
		dispatch(fetchTagList(value));
	};

	useEffect(()=> {
		if (!disabled) {
			handleSearch('');
		}
	}, []);

	useEffect(()=> {
		if (!disabled && initialData.length > 0) {
			getFullTags(initialData);
		}
	}, [initialData]);

	const getFullTags = async (tagIds) => {
		tagIds = tagIds.filter(tagId => !tagList.data.find(tag => tag.value === tagId));
		let newTags = await getTags(tagIds, true);
		dispatch(setTaglist(newTags));
	};

	return (
		<Compact block size="middle" direction='vertical'>
			<Row  align="middle" margin={10}>
			<BasicLabel
					title={title}
					tooltip={tooltip}
					required={required}
				/>
			</Row>
			<br/>
			<Space size="middle" direction='vertical' style={{width: '100%'}}>
				<Select
					title={title}
					style={{width: '100%', ...neutralStyle}}
					mode="multiple"
					maxTagCount="responsive"
					onChange={onChange}
					onSearch={handleSearch}
					value={value}
					defaultActiveFirstOption={false}
					suffixIcon={null}
					filterOption={false}
					options={tagList.data || []}
					loading={tagList.loading || loading}
					status={error?.show && error.type}
					popupClassName='tag-list__dropdown'
					disabled={disabled}
					tagRender={<Tag style={neutralStyle}/>}
				/>
			</Space>
			<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
		</Compact>
	);
}