import { Link } from 'react-router-dom';
import * as ROUTES from '@Router/routes';
import { getKeyFromUrl } from '../../utils/router';

const neutralStyle = {
  fontWeight: 'normal',
};

const headerStyle = {
	fontWeight: 'bold',
};

export default  [
	{
		label: 'Content',
		key: 'content',
		style: headerStyle,
		children: [
			{
				key: 'media',
				label: 'Media',
				style: neutralStyle,
				children: [
					{
						key: getKeyFromUrl(ROUTES.CREATE_MEDIA),
						label: (<Link to={ROUTES.CREATE_MEDIA}>Create a new media</Link>),
					},
					{
						key: getKeyFromUrl(ROUTES.LIST_MEDIA),
						label: (<Link to={ROUTES.LIST_MEDIA}>View all media</Link>)
					}
				],
			},
			{
				key: 'topics',
				label: 'Topics',
				style: neutralStyle,
				children: [
					{
						key: getKeyFromUrl(ROUTES.CREATE_TOPIC),
						label: (<Link to={ROUTES.CREATE_TOPIC}>Create a new topic</Link>),
					},
					{
						key: getKeyFromUrl(ROUTES.LIST_TOPIC),
						label: (<Link to={ROUTES.LIST_TOPIC}>View all topics</Link>),
					},
				]
			},
			{
				key: 'events',
				label: 'Events',
				style: neutralStyle,
				children: [
					{
						key: getKeyFromUrl(ROUTES.LIST_EVENT),
						label: (<Link to={ROUTES.LIST_EVENT}>View all events</Link>),
					},
				]
			},
			{
				key: 'tags',
				label: 'Tags',
				style: neutralStyle,
				children: [
					{
						key: getKeyFromUrl(ROUTES.TAGS),
						label: (<Link to={ROUTES.TAGS}>View all tags</Link>),
					},
				]
			},
		],
	},
	{
    type: 'divider',
  },
	{
		label: 'Homepage',
		key: 'homepage',
		style: headerStyle,
		children: [
			{
				key: getKeyFromUrl(ROUTES.RELEVANT_MEDIA),
				label: (<Link to={ROUTES.RELEVANT_MEDIA}>Relevant media</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.FEATURED_TOPICS),
				label: (<Link to={ROUTES.FEATURED_TOPICS}>Featured topics</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.SPOTLIGHTED_TOPICS),
				label: (<Link to={ROUTES.SPOTLIGHTED_TOPICS}>Spotlighted topic</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.HOMEPAGE_LIVE_STREAMING),
				label: (<Link to={ROUTES.HOMEPAGE_LIVE_STREAMING}>Spotlighted live stream</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.HOMEPAGE_MEDIA_CARDS),
				label: (<Link to={ROUTES.HOMEPAGE_MEDIA_CARDS}>Media information cards</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.HOMEPAGE_SERVICES_INFORMATION_CARDS),
				label: (<Link to={ROUTES.HOMEPAGE_SERVICES_INFORMATION_CARDS}>Services information cards</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.HOMEPAGE_SERVICE_MESSAGE),
				label: (<Link to={ROUTES.HOMEPAGE_SERVICE_MESSAGE}>Service message</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.HOMEPAGE_PROMOTIONAL_BANNER),
				label: (<Link to={ROUTES.HOMEPAGE_PROMOTIONAL_BANNER}>Promotional banner</Link>),
				style: neutralStyle,
			},
		],
	},
	{
    type: 'divider',
  },
	{
		label: 'Monitoring',
		key: 'monitoring',
		style: headerStyle,
		children: [
			{
				key: getKeyFromUrl(ROUTES.DASHBOARD_FILE_PROCESSING),
				label: (<Link to={ROUTES.DASHBOARD_FILE_PROCESSING}>File processing</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.DASHBOARD_MOST_VISITED),
				label: (<Link to={ROUTES.DASHBOARD_MOST_VISITED}>Most visited media</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.DASHBOARD_MOST_DOWNLOADED),
				label: (<Link to={ROUTES.DASHBOARD_MOST_DOWNLOADED}>Most downloaded media</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.SYSTEM_DASHBOARDS),
				label: (<Link to={ROUTES.SYSTEM_DASHBOARDS}>System dashboards</Link>),
				style: neutralStyle,
			},
		]
	},
	{
    type: 'divider',
  },
	{
		label: 'Users',
		key: 'users',
		style: headerStyle,
		children: [
			{
				key: getKeyFromUrl(ROUTES.CREATE_USER),
				label: (<Link to={ROUTES.CREATE_USER}>Create a new user</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.LIST_USER),
        label: (<Link to={ROUTES.LIST_USER}>View all users</Link>),
				style: neutralStyle,
			}
		],
	},
	{
    type: 'divider',
  },
	{
		label: 'Tasks',
		key: 'tasks',
		style: headerStyle,
		children: [
			{
				key: getKeyFromUrl(ROUTES.CREATE_TASK),
				label: (<Link to={ROUTES.CREATE_TASK}>Create a task</Link>),
				style: neutralStyle,
			},
			{
        key: getKeyFromUrl(ROUTES.LIST_TASK),
        label: (<Link to={ROUTES.LIST_TASK}>View all tasks</Link>),
				style: neutralStyle,
      },
		],
	},
	{
    type: 'divider',
  },
	{
		label: 'Multilingual dictionaries',
		key: 'multilingual-dictionaries',
		style: headerStyle,
		children: [
			{
				key: getKeyFromUrl(ROUTES.SYNONYMS),
				label: (<Link to={ROUTES.SYNONYMS}>Synonyms</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.HYPONYMS),
				label: (<Link to={ROUTES.HYPONYMS}>Hyponyms</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.ACRONYMS),
				label: (<Link to={ROUTES.ACRONYMS}>Acronyms</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.KEYWORDS_EXPANSION),
				label: (<Link to={ROUTES.KEYWORDS_EXPANSION}>Keywords expansion</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.ALIASES),
				label: (<Link to={ROUTES.ALIASES}>Aliases</Link>),
				style: neutralStyle,
			},
			{
				key: getKeyFromUrl(ROUTES.AUTOCORRECTION_WORDS),
				label: (<Link to={ROUTES.AUTOCORRECTION_WORDS}>Autocorrection words</Link>),
				style: neutralStyle,
			},
		],
	}
];