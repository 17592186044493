import { useRef } from 'react';

import BulkUpload from '@Components/BasicComponents/BulkUpload';
import Validate from '@Components/Validate';

import { checkAudioNames } from '@/utils/names';
import { fileRequired, filesUploaded } from '@/utils/errors/list';

import useNotification from '@/hooks/notifications';
import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';
import { useEffect } from 'react';

const tooltip = `To upload multiple media files simultaneously for large batches of content. Only mp3 files with the following name are accepted: \n{reference ID}[{lang}-audio].mp3 where: {referenceId} is the same referenceId as specified above and {lang} is the language of the audio.`;

export default function AudioBulkUpload ({selector}) {
	const {files, epId, setFile, removeFileByUid} = selector();
	const {openNotification} = useNotification();

	const useIbmS3UploaderRef = useRef(useIbmS3Uploader({setFile, removeFile: removeFileByUid, bucketPrefix: epId.data + '/'}));
	const {handleUpload, handleRemove, updateBucketPrefix} = useIbmS3UploaderRef.current;

	useEffect(() => {
		updateBucketPrefix(epId.data + '/');
	}, [epId.data]);

	return <>
		<Validate requirements={[fileRequired, filesUploaded]}>
			<BulkUpload
				value={files}
				handleUpload={handleUpload}
				accept=".mp3"
				beforeUpload={checkAudioNames({id: epId.data, openNotification})}
				handleRemove={handleRemove}
				tooltip={tooltip}
				required
			/>
		</Validate>
	</>;
}