import { useEffect, useMemo, useState } from 'react';

import { Space } from 'antd';

import useNotification from '@/hooks/notifications';

import SortableTable from '@Components//Tables/SortableTable';

import { highlightItem } from '@/utils/fetchFunctions/highlight';
import useFetchListData from '@/hooks/useFetchListData';

export default function OrderHighlightedMedias({url}) {
	const { openNotification } = useNotification();

	const params = useMemo(() => {
		return {url, highlighted: true};
	}, []);

	const {data, loading, error, getData} = useFetchListData({params, useDefaultParams: true});

	const sortedData = useMemo(() => data ? [...data]?.sort((a, b) => a?.position - b?.position) : [], [data]);
	const [loadingChange, setLoadingChange] = useState(false);

	useEffect(() => {
		if (error) {
			showError(error);
		}
	}, [error]);

	function showError (error) {
		openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
	}

	const saveItems = async (items) => {
		setLoadingChange(true);
		Promise.all(items.map(({id, position}) => highlightItem({url, id, position})))
			.then(() => {
				setLoadingChange(false);
				getData();
			})
			.catch(err => {
				showError(err);
			});
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<>
				<SortableTable
					data= {sortedData}
					loading={loading || loadingChange}
					saveItems={saveItems}
					idKey='mediaId'
				/>
			</>
		</Space>
	);
}