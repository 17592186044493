import { useContext, useState } from "react";
import { Select, Spin, Tabs } from "antd";

import { EventContext } from "@/context/eventContext/eventContext";

const options = (name) => [
    {label: 'UNSELECTED ' + name, value: 'unselected'},
    {label: 'SELECTED ' + name, value: 'selected'}
];

export default function Associated ({name, table, otherComponent}) {
    const [option, setOption] = useState('selected');

    const { eventId } = useContext(EventContext);

    const Table = table;
    const OtherComponent = otherComponent;

    const items = [
        {
            key: '1',
            label: <span>MANUALLY ASSOCIATED {name} <Select
                    options={options(name)}
                    value={option}
                    onChange={setOption}
                    style={{width: 200, marginLeft: 10}}
                /></span>,
            children: <Table
                selected={option === 'selected'}
                url={`event/${eventId}/medias`}
            />,
        },
        {
            key: '2',
            label: `ASSOCIATED ${name} BY ${name === 'TOPICS' ? 'TAG' : 'METADATA'}`,
            children: <OtherComponent />,
        },
    ];

    return (
        <Spin spinning={false}>
            <Tabs defaultActiveKey="1" items={items} size="large" />
        </Spin>
    );
}